import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/ProductXGP/PageBanner'
import Footer from "../components/App/Footer"
import ProductxgpOne from '../components/ProductXGP/ProductxgpOne'

const Productxgp = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="XG Automation Platform" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="XG Automation Platform" 
            />
            <ProductxgpOne />
            <Footer />
        </Layout>
    );
}

export default Productxgp