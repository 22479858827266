import React from 'react'
import icon5 from '../../assets/images/service-icons/icon-5.png'
import icon2 from '../../assets/images/service-icons/icon-2.png'
import icon3 from '../../assets/images/service-icons/icon-3.png'
import icon4 from '../../assets/images/service-icons/icon-4.png'
import icon1 from '../../assets/images/product-xgp/icon1.png'
import productImg1 from '../../assets/images/product-xgp/product-img1.png'
import productImg2 from '../../assets/images/product-xgp/product-img2.png'

const ProductxgpOne = () => {
    return (
        <section className="productxgp-area pt-md-70 pb-100">
            <div className="container">
                <div className="section-title pb-md-5 w-75 mx-auto">
                    <p>Deploying a cellular technology takes a lot of testing. After deployment it takes a lot of monitoring. 
                    XG Automation Platform (XGP) saves time and money by providing a comprehensive 
                    framework for mobile technology end-to-end test automation.</p>
                </div> 

                <div className="container-test mx-auto">
                    <div className="section-title">
                        <h2 className="pb-4">End-to-End Testing with XGP</h2>
                        <p>End to end testing covers validation of a capability from the origination of the service to the consumption of the service, covering the complete chain of technologies. With end to end testing a technician or a support team member can identify a problem with a service which might be caused by any component in the technology chain.</p>
                    </div>

                    <p className="font-weight-bold text-left">XGP provides an all in one platform with a comprehensive set of test capabilities over different RF technologies:</p>

                    <div className="main-section">
                        <div className="card mb-4">
                            <div className="card-body d-flex justify-content-start flex-md-row flex-column">
                                <img src={icon1} class="align-self-start" alt="icon" />
                                <div className="px-md-3">
                                    <h3 className="card-title">RF Technologies:</h3>
                                    <p className="card-text">2G, 3G, 4G, 5G</p>
                                </div>
                            </div>
                        </div>  

                        <div className="card mb-4">
                            <div className="card-body d-flex justify-content-start flex-md-row flex-column">
                                <img src={icon1} class="align-self-start" alt="icon" />
                                <div className="px-md-3">
                                    <h3 className="card-title">Testing Capabilities:</h3>
                                    <p className="card-text">Voice, VoLTE, SMS, STK, Broadband Data, Narrowband Data</p>
                                </div>
                            </div>
                        </div> 

                        <div className="card mb-4">
                            <div className="card-body d-flex justify-content-start flex-md-row flex-column">
                                <img src={icon1} class="align-self-start" alt="icon" />
                                <div className="px-md-3">
                                    <h3 className="card-title">Protocols:</h3>
                                    <p className="card-text">TCP/IP, UDP, HTTP, TLS/DTLS, CoAP, MQTT, LWM2M and more</p>
                                </div>
                            </div>
                        </div>      

                        <div>
                            <img src={productImg1} className="w-100 p-0" alt="product" />                       
                        </div>
                        <p className="text-center mt-3">How it works</p>             
                    </div>
                </div>
                    
                <div className="section-title py-md-5 mt-5 w-75 mx-auto">
                    <p>XGP consists of programmable GSM radios and a distributed test automation framework. 
                    With that, it provides an extremely agile and cost-effective framework for many types of 
                    testing required for network capabilities or applications in a mobile network.</p>
                </div> 
            </div>

            <div className="container w-75">
                <div className="section-title">
                    <h2>For Network Operators</h2>      
                </div> 
                <div className="Product-area">
                    <div className="container service-cards pb-100 px-0">
                        <div className="row row-cols-1 row-cols-md-2 px-0">           

                            <div className="col align-self-center px-0">
                                <div className="row justify-content-around justify-content-md-around">
                                    <div className="card w-75 p-md-4 p-1">
                                        <div className="card-body">
                                        <img src={icon2} alt="service" />
                                        <h3 className="card-title pt-md-5 pt-3">Lab Testing</h3>
                                        <p className="card-text">In faraday caged lab setups, XGP makes testing and troubleshooting activities easy by providing a wide range of test capabilities powered by a single test platform</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-around">
                                    <div className="card w-75 p-md-4 p-1">
                                        <div className="card-body">
                                        <img src={icon4} alt="service" />
                                        <h3 className="card-title pt-md-5 pt-3">Service Monitoring</h3>
                                        <p className="card-text">For network services or applications that require an SLA, XGP is a reliable service monitor to monitor service levels and making alters in case of anomalies.</p>
                                        </div>
                                    </div>
                                </div>              
                            </div>

                            <div className="col px-0">  
                                <div className="row justify-content-around justify-content-md-around">  
                                    <div className="card w-75 p-md-4 p-1">
                                        <div className="card-body">
                                        <img src={icon5} alt="service" />
                                        <h3 className="card-title pt-md-5 pt-3">Benchmarking</h3>
                                        <p className="card-text">XGP makes easier service benchmarking either in specific location or in drive tests by fully automating the test scenarios and logging service parameters</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-around justify-content-md-around">
                                    <div className="card w-75 p-md-4 p-1">
                                        <div className="card-body">
                                        <img src={icon3} alt="service" />
                                        <h3 className="card-title pt-md-5 pt-3">Deployment Testing</h3>
                                        <p className="card-text">When production cells are provisioned, XGP allows repetitive test activities faster and cost effective</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="p-lg-5">
                            <img src={productImg2} className="w-100" alt="product" />
                            <p className="mt-3 text-center">How it is used</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container w-75">
                <div className="section-title ">
                    <h2>For Network Testing Providers</h2>                
                </div> 
                <div className="col providers-section">
                    <div className="bg-provider">
                        <div className="row p-4 mb-3">
                            <p>Automate Test Scenarios: Develop all test scenarios in a test engagement as organized test suites. Reuse and execute scripted test cases saving time and effort.</p>
                        </div>
                    </div>
                    <div className="bg-provider">
                        <div className="row p-4 mb-3">
                            <p>Multichannel Test Automation: XGP supports RF Testing, Web Application Testing, API Testing and Mobile App testing with different test agents. You may develop complex test cases that span across multiple channels thanks to the multi-channel capability.</p>
                        </div>
                    </div>
                    <div className="bg-provider">
                        <div className="row p-4 mb-3">
                            <p>Remote Testing: XGP has it’s built in Distributed Test Coordinator. Your test team does not necessarily require to be present in the test lab to carry out testing.</p>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default ProductxgpOne